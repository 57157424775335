import { render } from 'storyblok-rich-text-react-renderer';
import InfoBox from '../blocks/InfoBox';

const PopularSection = ({ blok }) => (
    <div className={ `pop-features-section ${ blok.section_class }` }>
        <div className="container mx-auto">
            <div className="flex info-box-wrapper flex-wrap justify-between">
                <h2 className="heading">
                    { render(blok.heading) }
                </h2>

                { blok.info_boxes && blok.info_boxes.map((info_box, index) =>
                    <InfoBox props={ info_box } key={ index } />
                ) }
            </div>
        </div>
    </div>
);

export default PopularSection;
