import { useState } from 'react';
import Button from '../blocks/Button';
import { isMobile } from 'react-device-detect';

const OurProcessBoxes = ({ blok }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [directionClass, setDirectionClass] = useState();
    const handleClick = (index, e) => {
        if (e.target.classList.contains('arrow')) {
            return;
        }

        setActiveIndex(index);
    }
    const handleArrowClick = (index, eL) => {
        setActiveIndex(index);

        if (eL.target.classList.contains('left')) {
            setDirectionClass('slideleft');
        } else {
            setDirectionClass('slideright');
        }
    }
    const checkActive = (index, className) => activeIndex === index ? className + ' ' + directionClass : '';
    const displayBlockClass = (isMobile) ? 'hide-block' : 'display-block';

    return (
        <div className={ `our-process-boxes ${ blok.section_class } ${ displayBlockClass }` }>
            <div className="container mx-auto">
                <h2 className="heading">
                    { blok.Heading }
                </h2>

                <p className="sub-heading">
                    { blok.sub_heading }
                </p>

                <div className="flex info-box-wrapper justify-between">
                    { blok.info_boxes && blok.info_boxes.map((info_box, index) => (
                        // <InfoBox props={ info_box } key={ index } onClick={ () => handleClick(index) } active_class={ `${ checkActive(index, 'active') }` } />
                        <div
                            className={ `info-box ${ info_box.title.split(' ').at(0) } ${ checkActive(index, 'active') }` }
                            onClick={ (e) => handleClick(index, e) }
                            key={ index }>

                            { info_box.box_link.cached_url &&
                                <a href={ info_box.box_link.cached_url } className="infobox-box-link"></a>
                            }

                            <div className="text-wrapper">
                                <h3 className="infobox-heading"><span>{ index + 1 }. </span>{ info_box.title }</h3>

                                <div className="info-description">
                                    { info_box.description }
                                </div>

                                { info_box.buttons && info_box.buttons.map((button, index) =>
                                    <Button props={ button } key={ index } />
                                ) }
                            </div>

                            <div className="arrow left" onClick={ (eL) => handleArrowClick(index - 1, eL) }></div>
                            <div className="arrow right" onClick={ (eL) => handleArrowClick(index + 1, eL) }></div>
                            <img src={ info_box.icon.filename } className="infobox-image" />
                        </div>
                    )) }
                </div>
            </div>
        </div>
    );
};

export default OurProcessBoxes;
