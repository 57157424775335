import React from 'react';
import Head from './Head';
import Navigation from './Navigation';
import Footer from './Footer';

const Layout = ({ children }) => {

	var metaTags = children.props.blok.metatags;
	console.log("children ",children);

	return(
	  <div className="wrapper bg-white">
		    <Head props={metaTags} />
		    <Navigation />
		    {children}
		    <Footer />
	  </div>
	);
};

export default Layout;
