import { useState } from 'react';
import logo from '../images/sb-logo-wh.svg';
import NavigationMenu from '../blocks/NavigationMenu';


const Navigation = () => {

    const [navbarOpen, setNavbarOpen] = useState(false);
    const subdirectory = (window.location.hostname === 'development.solutionbuilt.com'
        ? '/' + window.location.pathname.split('/')[1]
        : ''
    );
    const base_url = (window.location.hostname === 'localhost'
        ? '' :
        `${ window.location.protocol }//${ window.location.hostname }${ subdirectory }`
    );

    const openMobileMenu = (e) => {
        var btnClassName = e.currentTarget.className;
        setNavbarOpen((prev) => !prev);
        document.body.style.overflow = (btnClassName.includes("close")) ? "hidden" : "auto";
    }

    return (
        <header className="w-full">
            <nav role="navigation">
                <div className="container mx-auto p-8 lg:py-10 flex gap-4 items-center justify-between md:max-lg:flex-col">
                    <div>
                        <a href={`${ base_url }/`}>
                            <img src={ logo } className="logo-header" width="280px" />
                        </a>
                    </div>

                    <div className="ml-auto mobile-menu md:hidden">
                        <button
                            className={ `flex items-center px-3 border rounded ${ (navbarOpen) ? 'open' : 'close' }` }
                            type="button"
                            onClick={ (e) => openMobileMenu(e) }>

                            <svg className="icon-menu-toggle w-4 py-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                    <path className="line line-1" d="M5 13h90v14H5z"/>
                                    <path className="line line-2" d="M5 43h90v14H5z"/>
                                    <path className="line line-3" d="M5 73h90v14H5z"/>
                                
                            </svg>
                        </button>
                    </div>

                    <NavigationMenu open={ navbarOpen } openMobileMenu={openMobileMenu}/>
                </div>
            </nav>
        </header>
    );
};

export default Navigation;
