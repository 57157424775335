import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { lazyWithPreload } from 'react-lazy-with-preload';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

import { storyblokInit, apiPlugin, useStoryblok } from '@storyblok/react';

import BlogPost from './components/BlogPost';
import PostsList from './components/PostsList';
import Page from './components/Page';
import PopularSection from './components/PopularSection';
import TabServicesBoxes from './components/TabServicesBoxes';
import OurProcessBoxes from './components/OurProcessBoxes';
import MobileCarousel from './components/MobileCarousel';


const HeroSection = lazyWithPreload(() => import('./components/HeroSection'));
HeroSection.preload();

const CommonSection = lazyWithPreload(() => import('./components/CommonSection'));
CommonSection.preload();

const CustomLayout = lazyWithPreload(() => import('./components/CustomLayout'));
CustomLayout.preload();

const MasonryGrid = lazyWithPreload(() => import('./components/MasonryGrid'));
MasonryGrid.preload();

const ClientsSection = lazyWithPreload(() => import('./components/ClientsSection'));
ClientsSection.preload();

const SliderSection = lazyWithPreload(() => import('./components/SliderSection'));
SliderSection.preload();

const OurMissionSection = lazyWithPreload(() => import('./components/OurMissionSection'));
OurMissionSection.preload();

const Tabs = lazyWithPreload(() => import('./components/Tabs'));
Tabs.preload();

const SBTimeLine = lazyWithPreload(() => import('./components/SBTimeLine'));
SBTimeLine.preload();

const Form = lazyWithPreload(() => import('./components/Form'));
Form.preload();

const HeroAnimatedSection = lazyWithPreload(() => import('./components/HeroAnimatedSection'));
HeroAnimatedSection.preload();

const TeamQualitySection = lazyWithPreload(() => import('./components/TeamQualitySection'));
TeamQualitySection.preload();

const FaqTabs = lazyWithPreload(() => import('./components/FaqTabs'));
FaqTabs.preload();

const GoogleMapSection = lazyWithPreload(() => import('./components/GoogleMapSection'));
GoogleMapSection.preload();

const JobSlider = lazyWithPreload(() => import('./components/JobSlider'));
JobSlider.preload();

const MonthlyServicesSection = lazyWithPreload(() => import('./components/MonthlyServicesSection'));
MonthlyServicesSection.preload();

const ProjectList = lazyWithPreload(() => import('./components/ProjectList'));
ProjectList.preload();

const TabsProcess = lazyWithPreload(() => import('./components/TabsProcess'));
TabsProcess.preload();


const components = {
    page: Page,
    'hero_section': HeroSection,
    'hero_animated_section': HeroAnimatedSection,
    'common_section': CommonSection,
    'custom_layout': CustomLayout,
    'masonry_grid': MasonryGrid,
    'clients_section': ClientsSection,
    'slider': SliderSection,
    'our_mission_section': OurMissionSection,
    'tabbed-content-section': Tabs,
    'tab-process':TabsProcess,
    'faq_tab_section': FaqTabs,
    'timeline': SBTimeLine,
    'form_section': Form,
    'team_quality_section': TeamQualitySection,
    'GoogleMap': GoogleMapSection,
    'job_slider': JobSlider,
    'monthly_service_section': MonthlyServicesSection,
    'projects':ProjectList,
    'popular_features_section': PopularSection,
    TabServicesBoxes: TabServicesBoxes,
    OurProcessBoxes: OurProcessBoxes,
    'mobile-carousel': MobileCarousel
};

storyblokInit({
    accessToken: '0pRGr7PzeCRdAb8bJ0oqGQtt',
    use: [apiPlugin],
    // for spaces located in the US or China:
    components,
});


/*
CODE TO CREATE SITEMAP XML FILE
import StoryblokClient from 'storyblok-js-client'

const Storyblok = new StoryblokClient({
    accessToken: '0pRGr7PzeCRdAb8bJ0oqGQtt'
});
let links = await Storyblok.getAll('cdn/links', {
    version: 'published'
});

// 001 define the prefix of URL with HTTPS protocol and the hostname
const prefixUrl = 'https://www.solutionbuilt.com';
// 002 loop through the links via map() function
let sitemap_entries = links.map((link) => {
// 003 skipping the folders
    if (link.is_folder) return ''
// 004 creating the URL loc element with the real_path
    return `\n    <url><loc>${prefixUrl}${link.real_path}</loc></url>`
})
// 005 compose the whole XML with the header
let sitemap = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    ${sitemap_entries.join('')}
</urlset>`

console.log("sitemap ",sitemap);*/


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter >
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/projects" element={<App />} />
            <Route path="/integrations" element={<App />} />
            <Route path="/digital-marketing" element={<App />} />
            <Route path="/contact" element={<App />} />
            <Route path="/get-a-quote" element={<App />} />

            <Route path="/maintenance-services" element={<App />} />
            <Route path="/maintenance-services/website-maintenance" element={<App />} />
            <Route path="/maintenance-services/website-hosting" element={<App />} />
            <Route path="/maintenance-services/mobile-app-maintenance" element={<App />} />
            <Route path="/maintenance-services/mobile-device-management" element={<App />} />


            <Route path="/mobile-apps" element={<App />} />
            <Route path="/mobile-apps/mobile-app-development" element={<App />} />
            <Route path="/mobile-apps/mobile-app-design" element={<App />} />
            <Route path="/mobile-apps/android-apps" element={<App />} />
            <Route path="/mobile-apps/ios-apps" element={<App />} />
            <Route path="/mobile-apps/cross-platform-apps" element={<App />} />
            <Route path="/mobile-apps/healthcare-mobile-apps" element={<App />} />
            <Route path="/mobile-apps/enterprise-mobile-apps" element={<App />} />

            <Route path="/about" element={<App />} />
            <Route path="/about/faqs" element={<App />} />
            <Route path="/about/our-history" element={<App />} />
            <Route path="/about/join-us" element={<App />} />

            <Route path="/websites" element={<App />} />
            <Route path="/websites/website-development" element={<App />} />
            <Route path="/websites/website-design" element={<App />} />
            <Route path="/websites/e-commerce" element={<App />} />
            <Route path="/websites/web-portals" element={<App />} />
            <Route path="/websites/wordpress-websites" element={<App />} />
        </Routes>
    </BrowserRouter>
);
