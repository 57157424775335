import logo from '../images/sb-logo-wh.svg';
import youtubeIcon from '../images/youtube.svg';
import fbIcon from '../images/facebook-f.svg';
import instaIcon from '../images/instagram.svg';
import twitterIcon from '../images/twitter.svg';
import yelpIcon from '../images/yelp.svg';
import rssIcon from '../images/rss.svg';

const NavigationMenuFooter=() => {


    const subdirectory = (window.location.hostname === 'development.solutionbuilt.com'
        ? '/' + window.location.pathname.split('/')[1]
        : ''
    );
    const base_url = (window.location.hostname === 'localhost'
        ? ''
        : `${ window.location.protocol }//${ window.location.hostname }${ subdirectory }`
    );

    return (
        <div className={ `menu-wrapper w-full md:w-auto md:flex md:items-center justify-center`}>
            <a href={ `${ base_url }/` }>
                <img src={ logo } className="mobile-logo-header" width="313px" />
            </a>

            <ul className="flex gap-x-4 md:gap-x-8 xl:gap-x-12 menu">
                <li>
                    <a href= {`${ base_url }/mobile-apps`} >Mobile Apps</a>
                </li>
                <li>
                    <a href= {`${ base_url }/websites` } >websites</a>
                </li>
                <li>
                    <a href= {`${ base_url }/maintenance-services` } >Maintenance</a>
                </li>
                <li>
                    <a href= {`${ base_url }/integrations` }  >Integrations</a>
                </li>
                <li>
                    <a href= {`${ base_url }/about` } >About</a>
                </li>
                <li>
                    <a className="contact" href= {`${ base_url }/contact` } >Contact</a>
                </li>
            </ul>

            <ul className="socials">
                <li><a href="https://www.youtube.com/user/solutionbuilt" target="_blank" rel="noopener"><img src={ youtubeIcon } /></a></li>
                <li><a href="https://www.facebook.com/SolutionBuilt/" target="_blank" rel="noopener"><img src= {fbIcon } /></a></li>
                <li><a href="https://www.instagram.com/solutionbuilt/" target="_blank" rel="noopener"><img src={ instaIcon } /></a></li>
                <li><a href="https://twitter.com/SolutionBuilt" target="_blank" rel="noopener"><img src={ twitterIcon } /></a></li>
                <li><a href="https://www.yelp.com/biz/solutionbuilt-marietta-3" target="_blank" rel="noopener"><img src={ yelpIcon } /></a></li>
                <li><a href="https://www.solutionbuilt.com/blog/" target="_blank" rel="noopener"><img src={ rssIcon } /></a></li>
            </ul>
        </div>
    );
};

export default NavigationMenuFooter;
