import { Link } from 'react-router-dom';
import logo from '../images/sb-logo-wh.svg';
import youtubeIcon from '../images/youtube.svg';
import fbIcon from '../images/facebook-f.svg';
import instaIcon from '../images/instagram.svg';
import twitterIcon from '../images/twitter.svg';
import yelpIcon from '../images/yelp.svg';
import rssIcon from '../images/rss.svg';

const NavigationMenu=({ open, openMobileMenu }) => {

    var menu_class = (open) ? 'display-menu' : 'hide-menu';

    const subdirectory = (window.location.hostname === 'development.solutionbuilt.com'
        ? '/' + window.location.pathname.split('/')[1]
        : ''
    );
    const base_url = (window.location.hostname === 'localhost'
        ? ''
        : `${ window.location.protocol }//${ window.location.hostname }${ subdirectory }`
    );

    return (
        <div className={ `menu-wrapper w-full md:w-auto md:flex md:items-center justify-center ${ (open) ? 'display-menu' : 'hide-menu' }` }>
            <Link to={ `${ base_url }/` }>
                <img src={ logo } className="mobile-logo-header" width="313px" />
            </Link>

            <ul className="flex gap-x-4 md:gap-x-8 xl:gap-x-12 menu">
                <li>
                    <Link to= {`${ base_url }/mobile-apps`} onClick={ (e) => openMobileMenu(e) } >Mobile Apps</Link>
                </li>
                <li>
                    <Link to= {`${ base_url }/websites` } onClick={ (e) => openMobileMenu(e) } >websites</Link>
                </li>
                <li>
                    <Link to= {`${ base_url }/maintenance-services` } onClick={ (e) => openMobileMenu(e) } >Maintenance</Link>
                </li>
                <li>
                    <Link to= {`${ base_url }/integrations` } onClick={ (e) => openMobileMenu(e) } >Integrations</Link>
                </li>
                <li>
                    <Link to= {`${ base_url }/about` } onClick={ (e) => openMobileMenu(e) } >About</Link>
                </li>
                <li>
                    <Link className="contact" to= {`${ base_url }/contact` } onClick={ (e) => openMobileMenu(e) } >Contact</Link>
                </li>
            </ul>

            <ul className="socials">
                <li><a href="https://www.youtube.com/user/solutionbuilt" target="_blank" rel="noopener"><img src={ youtubeIcon } /></a></li>
                <li><a href="https://www.facebook.com/SolutionBuilt/" target="_blank" rel="noopener"><img src= {fbIcon } /></a></li>
                <li><a href="https://www.instagram.com/solutionbuilt/" target="_blank" rel="noopener"><img src={ instaIcon } /></a></li>
                <li><a href="https://twitter.com/SolutionBuilt" target="_blank" rel="noopener"><img src={ twitterIcon } /></a></li>
                <li><a href="https://www.yelp.com/biz/solutionbuilt-marietta-3" target="_blank" rel="noopener"><img src={ yelpIcon } /></a></li>
                <li><a href="https://www.solutionbuilt.com/blog/" target="_blank" rel="noopener"><img src={ rssIcon } /></a></li>
            </ul>
        </div>
    );
};

export default NavigationMenu;
