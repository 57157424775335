import { useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { render } from 'storyblok-rich-text-react-renderer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {isMobile} from 'react-device-detect';

const MobileCarousel = ({ blok }) => {

    const refContainer = useRef([]);

    const [activeClass, setActiveClass] = useState( blok.slides[0].slide_class);

    const displayBlockClass = (isMobile) ? 'display-block' : 'hide-block';

    const handleChange = (e, item) => {
        setActiveClass(item.props.className);
        let eLHeight = refContainer.current[e].clientHeight;
        document.querySelector(".slider-wrapper").style.height = eLHeight+"px";
    };

    return (
        <section className={ `mobile-carousel ${ blok.section_class } ${ displayBlockClass }` }>
            <div className={ `inner-slider container mx-auto ${ activeClass }` }>
                <div className="slider-title">
                    <h2>{ blok.section_title }</h2>

                    <span className="slider-copy mt-8">
                        { render(blok.section_copy) }
                    </span>
                </div>

                <Carousel className="mob-carousel"
                    showIndicators={ false }
                    showThumbs={ false }
                    showStatus={ false }
                    dynamicHeight={ false }
                    onChange={ (e, item) => handleChange(e, item) }>

                    { blok.slides && blok.slides.map((slide, index) => (
                        <div className={ `slider-content ${ slide.slide_class }` } key={ index } >
                            <div 
                                className="slide-data" 
                                ref= { ref => {
                                    refContainer.current[index] = ref; // took this from your guide's example.
                                }}>
                                <img src={ slide.image.filename } alt="travel image" className="slide-image" />
                                <h4 className="slide-title">{ slide.title }</h4>

                                <span className="slide-copy">
                                    { render(slide.copy) }
                                </span>
                            </div>
                        </div>
                    )) }
                </Carousel>
            </div>
        </section>
    );
};

export default MobileCarousel;
