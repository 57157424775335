import React, { useState } from 'react';
import Button from './Button';
import "../components/info-box.css";


const InfoBox=({ props }) => (
    <div className={ `info-box ${ props.title.split(' ').at(0) }` }>
        { props.box_link.cached_url &&
            <a href={ props.box_link.cached_url } className="infobox-box-link"></a>
        }
        { props.icon.filename &&
            <img src={ props.icon.filename } className="infobox-image" />
        }
        <div className="text-wrapper">
            <h3 className="infobox-heading">{ props.title }</h3>

            <div className="info-description">
                { props.description }
            </div>

            { props.buttons && props.buttons.map((button, index) =>
                <Button props={ button } key={ index } />
            ) }
        </div>
    </div>
);

export default InfoBox;
