import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';

const BlogPost = ({ blok }) => {
    return (
        <div { ...storyblokEditable(blok) } key={ blok._uid }>
            <div className="bg-white-half pt-16 single-post container mx-auto ">
                <div className="flex flex-col items-center">
                    <h1 className="mb-12">
                        { blok.title }
                    </h1>

                    <p className="mb-12">{ blok.intro }</p>

                    <img className="mb-12" src={ blok.image } />
                </div>

                <div className="mb-12">
                    { render(blok.long_text) }
                </div>
            </div>
        </div>
    );
};

export default BlogPost;
