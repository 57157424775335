import { useStoryblok, StoryblokComponent } from '@storyblok/react';
import './index.css';
import Layout from './components/Layout';

function App() {

    // Handle development server subdirectory
    const pathName = window.location.pathname.replace('/solutionbuilt-v6/', '/');

    const slug = (pathName === '/'
        ? 'home' // Production
        : pathName.replace('/', '') // Development/Staging
    );

    // console.log('slug', slug);

    const story = useStoryblok(
        slug,
        // {
        //     resolve_relations: ['featured-posts.posts', 'selected-posts.posts'],
        // },
        // {
        //     resolveRelations: ['featured-posts.posts', 'selected-posts.posts'],
        // },
    );

    console.log('story', story);

    if (!story?.content) {
        return null;
    }

    return (
        <Layout>
            <StoryblokComponent blok={story.content} />
        </Layout>
    );
}

export default App;
