import React from 'react';
import { HeadProvider, Title, Meta, Link } from 'react-head';
import favIcon from '../images/favicon.ico';

const Head = ({ props }) => {

    return (
        <HeadProvider>
            <base href="/" />
            <meta charSet="UTF-8" />
            <Title>{props.title || 'Solutionbuilt'}</Title>
            <Meta name="description" content={ props.description || ''} />
            <Meta name="viewport" content="width=device-width, initial-scale=1" />
            <Link rel="icon" type="image/x-icon" href={favIcon} />
        </HeadProvider>
    );
};

export default Head;
