import { useState } from 'react';
import logoRings from '../images/rings-white.svg'
import { render } from 'storyblok-rich-text-react-renderer';
import {isMobile, isTablet} from 'react-device-detect';

const TabServicesBoxes = ({ blok }) => {

    const [tabActiveIndex, setTabActiveIndex] = useState(0);
    const [iconActiveIndex, setIconActiveIndex] = useState(0);
    const [tabPanelIndex, setTabPanelIndex] = useState(1);
    const handleTabHover = (index, e) => {
        setTabActiveIndex(index);
        setTabPanelIndex(index + 1);
    }
    const handleIconHover = (index, e) => {
        setIconActiveIndex(index);
    }
    const checkTabActive = (index, className) => tabActiveIndex === index ? className : '';
    const checkIconActive = (index, className) => iconActiveIndex === index ? className : '';
    const displayBlockClass =  (isMobile || isTablet) ? 'display-block' : 'hide-block';

    return (
        <section className={ `tab-services ${ blok.section_class } container mx-auto`}>
            <div className={ `tab-services-wrapper flex ${ blok.section_class }` }>
                <div className="tabs">
                    { blok.entries && blok.entries.map((entrie, index) => (
                        <button
                            className={ `tab ${ checkTabActive(index, 'active') } flex items-center` }
                            onMouseEnter={ (e) => handleTabHover(index, e) }
                            key={ index }>
                                <div className="tab-icon-container"><img src={ entrie.tab_icon.filename } /></div>
                                <h3>{ entrie.tab_label.replace(" ", "\n") }</h3>
                        </button>
                    )) }

                    <div className={ `panels for-mobile-only ${ displayBlockClass }` } style={ { order: tabPanelIndex } }>
                        { blok.entries && blok.entries.map((entry, index) => (
                            <div className={ `panel ${ checkTabActive(index, 'active') } ${ entry.tab_label }` }
                                key={ index }
                                style={ { backgroundImage: `url(${ entry.panel_bg_image.filename })` } }>

                                <div className="panel-title-container">
                                    <h2>{ entry.panel_title }</h2>
                                    <span className="service-description">
                                        { render(entry.panel_description) }
                                    </span>
                                </div>

                                { (blok.entries[index].icons.length > 0) &&
                                    <div className="icons flex flex-row items-center justify-between">
                                        { blok.entries[index].icons && blok.entries[index].icons.map((icon, index) => (
                                            <div className={ `icon-wrapper ${ checkIconActive(index, 'active') }` } key={ index }>
                                                <img src={ icon.icon.filename }
                                                    onMouseEnter={ () => handleIconHover(index) } />
                                            </div>
                                        )) }
                                    </div>
                                }

                                { (blok.entries[index].icons.length > 0) &&
                                    <div className="description-wrapper">
                                        { blok.entries[index].icons && blok.entries[index].icons.map((icon, index) => (
                                            <div className={ `description-row ${ checkIconActive(index, 'active') }` } key={ index }>
                                                <h4>{ icon.title }</h4>

                                                <span className="hero-item-description">
                                                    { render(icon.description) }
                                                </span>
                                            </div>
                                        )) }
                                    </div>
                                }

                                { entry.items_list &&
                                    <div className="list-wrapper">
                                        { entry.items_list &&  (
                                            <span className="list">
                                                { render(entry.items_list) }
                                            </span>
                                        ) }
                                    </div>
                                }
                            </div>
                        )) }
                    </div>
                </div>

                <div className="panels">
                    { blok.entries && blok.entries.map((entry, index) => (
                        <div className={ `panel ${ checkTabActive(index, 'active') } ${ entry.tab_label }` }
                            key={ index }
                            style={ { backgroundImage: `url(${ entry.panel_bg_image.filename })` } }>

                            <div className="panel-title-container">
                                <h2>{ entry.panel_title }</h2>

                                <span className="service-description">
                                    { render(entry.panel_description) }
                                </span>
                            </div>

                            { (blok.entries[index].icons.length > 0) &&
                                <div className="icons flex flex-row items-center justify-between">
                                    { blok.entries[index].icons && blok.entries[index].icons.map((icon, index) => (
                                        <div className={ `icon-wrapper ${ checkIconActive(index, 'active') }` } key={ index }>
                                            <img src={ icon.icon.filename }
                                                onMouseEnter={ () => handleIconHover(index) } />
                                        </div>
                                    )) }
                                </div>
                            }

                            { (blok.entries[index].icons.length > 0) &&
                                <div className="description-wrapper">
                                    { blok.entries[index].icons && blok.entries[index].icons.map((icon, index) => (
                                        <div className={ `description-row ${ checkIconActive(index, 'active') }` } key={ index }>
                                            <h4>{ icon.title }</h4>

                                            <span className="hero-item-description">
                                                { render(icon.description) }
                                            </span>
                                        </div>
                                    )) }
                                </div>
                            }

                            { entry.items_list &&
                                <div className="list-wrapper">
                                    { entry.items_list &&  (
                                        <span className="list">
                                            { render(entry.items_list) }
                                        </span>
                                    ) }
                                </div>
                            }
                        </div>
                    )) }
                </div>
            </div>
        </section>
    );
};

export default TabServicesBoxes;
