import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

const Page = ({ blok }) => (
    <main { ...storyblokEditable(blok) } key={ blok._uid }>
        { blok.body
            ? blok.body.map((blok) => (
                    <StoryblokComponent blok={ blok } key={ blok._uid } />
                ))
            : null
        }
    </main>
);

export default Page;
